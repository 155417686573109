<template>
  <div id="about">
    <b-container class="base-container-x about-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div class="banner-inner about-us">
        <div v-if="loading == false" class="slideshow_caption">
          <div class="slideshow_inner_caption" style="position: absolute;">
            <div class="slideshow_align_caption">
              <h1 class="section-header-title">{{ $t('About us') }}</h1>
              <h3 class="article-content">{{ $t('Welcome to WYA International Community!') }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading == true" class="col-12 mt-4">
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
          <br/>
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading == false" class="col-12 mb-5">
        <div class="pt-5 d-block">
          <p class="article-content">
            {{ $t('AboutUs_section_1') }}
          </p>
        </div>
        <div class="mt-5">
          <h3 class="section-header-title text-primary-dark d-flex">{{ $t('OUR MISSION') }}</h3>
          <h4 class="section-sub-header-title text-primary-light d-block font-weight-400">
            {{ $t('Our mission is to share the Traditional Indian Yoga Knowledge and create a healthy living community worldwide.') }}
          </h4>
          <p class="article-content mt-3">{{ $t('AboutUs_section_mission_1') }}</p>
          <p class="article-content mt-3">{{ $t('AboutUs_section_mission_2') }}</p>
          <p class="article-content mt-3">{{ $t('AboutUs_section_mission_3') }}</p>
        </div>
        <div class="mt-5">
          <h3 class="section-header-title text-primary-dark d-flex">{{ $t('OUR VISION') }}</h3>
          <h4 class="section-sub-header-title text-primary-light d-block font-weight-400">
            {{ $t('We maintain the highest education quality and standards worldwide.') }}
          </h4>
          <p class="article-content mt-3">{{ $t('AboutUs_section_vision') }}</p>
        </div>
        <div class="mt-5">
          <h3 class="section-header-title text-primary-dark d-flex">{{ $t('OUR TEAM') }}</h3>
          <p class="article-content mt-3">{{ $t('AboutUs_section_team') }}</p>
        </div>
        <div class="mt-5">
          <h3 class="section-header-title text-primary-dark d-flex">{{ $t('OFFICIAL INFORMATION') }}</h3>
          <p class="article-content mt-3">{{ $t('AboutUs_section_information') }}</p>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'About',
  title: ' | About Us',
  data () {
    return {
      loading: true
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  #about {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
</style>
